// npm
import React from "react"

// structure
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/structure/container"
import Heading from "../components/structure/heading"

export default function PageNotFound() {
  const pageTitle = "Page not Found"

  return (
    <Layout>
      <SEO title={pageTitle} />
      <Container>
        <Heading>{pageTitle}</Heading>
      </Container>
    </Layout>
  )
}
